<template>
  <a-modal
    centered
    width="1000px"
    :visible="show"
    :title="$t('trans_record.name')"
    :maskClosable="false"
    @cancel="cancel"
    class="preview-trans-record-dialog"
  >
    <a-table
      :data-source="dataSource"
      :pagination="pagination"
      :bordered="true"
      :loading="loading"
      size="middle"
      rowKey="id"
      class="head-bg"
    >
      <a-table-column key="targetCode" :title="$t('orderInfo.lang_pair')" width="33%">
        <template slot-scope="record">
          <span>{{ $store.getters['app/getLangNameByCode'](record.sourceCode) }}</span>
          >
          <span>{{ $store.getters['app/getLangNameByCode'](record.targetCode) }}</span>
        </template>
      </a-table-column>
      <a-table-column key="sourceContent" :title="$t('trans_record.source_text')" width="33%">
        <template slot-scope="record">
          <span>{{ record.sourceContent }}</span>
        </template>
      </a-table-column>
      <a-table-column key="targetContent" :title="$t('trans_record.trans_text')" width="33%">
        <template slot-scope="record">
          <span>{{ record.targetContent }}</span>
        </template>
      </a-table-column>
    </a-table>
  </a-modal>
</template>

<script>
export default {
  name: 'previewTransRecordDialog',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataSource: [],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        size: 'middle',
        pageSizeOptions: ['10', '20', '50'],
        showSizeChanger: true,
        total: 0,
        onShowSizeChange: (page, limit) => {
          this.pagination.current = 1
          this.pagination.pageSize = limit
          this.fetch()
        },
        onChange: (page) => {
          this.pagination.current = page
          this.fetch()
        },
      },
    }
  },
  watch: {
    show(v) {
      if (v) {
        this.fetch()
      } else {
        setTimeout(() => {
          this.pagination.current = 1
          this.pagination.pageSize = 10
        }, 100)
      }
    },
  },
  mounted() {},
  methods: {
    async fetch() {
      try {
        this.loading = true
        const { current: page, pageSize: limit } = this.pagination
        const params = { orderId: this.$route.query.id, page, limit }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('translationHistoryList', params)
        this.dataSource = data.list
        this.pagination.total = data.total
        console.log(`this.dataSource`, this.dataSource)
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    cancel() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
.preview-trans-record-dialog {
  ::v-deep {
    .ant-modal-footer {
      display: none;
    }
  }
}
</style>
