<template>
  <a-modal
    :width="580"
    :title="$t('orderInfo.btn_upload_finish')"
    :cancelText="$t('cancel')"
    :okText="$t('submit')"
    @cancel="handleCancel"
    :maskClosable="false"
    :visible="show"
  >
    <!-- 上传终稿 -->
    <div class="first-row _mb-30">
      <div class="_w-120">{{ $t('orderInfo.final_document_link') }}：</div>
      <a-input
        v-model="submitParams.fileUrl"
        :placeholder="$t('orderInfo.please_enter_file_link_for_onedrive')"
      ></a-input>
    </div>

    <div class="_flex mb-6">
      <div class="_w-120">{{ $t('orderInfo.final_document_show') }}：</div>
      <div class="textarea-box">
        <a-textarea
          v-model="submitParams.describeInfo"
          :maxLength="500"
          :placeholder="$t('orderInfo.find_paper_holder')"
          :auto-size="{ minRows: 3, maxRows: 7 }"
          @change="textareaChange"
        />
        <div :class="inputNumber > 500 ? 'word-number-active' : 'word-number'">{{ inputNumber }}/500</div>
      </div>
    </div>

    <div class="second-row">
      <div class="_w-97">{{ $t('orderInfo.final_document') }}：</div>
      <NeoUploadButton
        :title="$t('uploadButton.title')"
        v-model="submitParams.finalDrafts"
        :showNote="false"
        :showFileList="true"
        :showIcon="false"
        :accept="acceptMap.orderSourceFile"
        :maxLength="1"
        :maxSize="100"
        @input="handleSubmitFinalDraft"
      />
    </div>

    <div class="third-row">
      <div class="third-row-left _hide2">1</div>
      <div class="third-row-right">支持扩展名：.zip .docx .pptx .txt .xlsx .sdlxliff</div>
    </div>

    <div slot="footer" class="_center">
      <a-button @click="handleSubmit" :disabled="disabled">确认提交</a-button>
    </div>
  </a-modal>
</template>

<script>
import NeoUploadButton from '@/components/upload/NeoUploadButton'
const { localeDict, acceptMap } = window.$g

export default {
  name: 'UploadFinalDraftModal',
  data() {
    return {
      acceptMap,
      localeDict,
      submitParams: {
        finalDrafts: [],
        remark: [],
        fileUrl: '',
      },
      loading: false,
      disabled: false,
      confirmLoading: false,
      inputNumber: 0,
    }
  },
  props: {
    show: {
      default: false,
    },
    parent: {},
  },
  watch: {
    submitParams: {
      deep: true,
      handler(submitParams) {
        const { fileUrl } = submitParams
        fileUrl && (this.disabled = false)
      },
    },
    show(v) {
      // !v && (this.submitParams.finalDrafts = [])
      if (!v) {
        setTimeout(() => {
          this.$g.resetData(this)
          this.disabled = false
        }, 100)
      }
    },
  },
  components: {
    NeoUploadButton,
  },
  methods: {
    async handleSubmit() {
      try {
        // 先校验

        const { finalDrafts, fileUrl, describeInfo } = this.submitParams
        if (!fileUrl && !finalDrafts.length) {
          return this.$message.error(this.$t('orderInfo.final_document_no_empty'))
        }

        const _finalDrafts = this.submitParams.finalDrafts.map(({ name, url, size }) => {
          return { name, url, size }
        })

        const params = {
          fileUrl,
          finalDrafts: _finalDrafts,
          orderId: this.$route.query.id,
          describeInfo,
        }
        this.loading = true
        this.disabled = true
        await this.$http('submitFinalDraft', params)
        this.loading = false
        this.$message.success(this.$t('orderInfo.submitFinalDraftSuccess'))
        this.$emit('uploadOk')
        this.$emit('close')
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    handleCancel() {
      this.$emit('close')
    },
    // 上传终稿
    handleSubmitFinalDraft(data) {
      if (data[0]?.status == 'done') {
        this.disabled = false
        this.$message.success(this.$t('upload_file_succeeded'))
      } else {
        this.disabled = true
      }
    },

    textareaChange(e) {
      this.inputNumber = e.target.value.length
    },
  },
}
</script>

<style lang="less" scoped>
.first-row {
  display: flex;
  align-items: center;
}
.second-row {
  display: flex;
  align-items: center;
}
.third-row {
  display: flex;
  margin-top: 15px;
  &-left {
    width: 97px;
  }
  &-right {
    color: @text_color_2;
    font-size: @font_size_1;
  }
}

.textarea-box {
  width: 100%;
}
.word-number {
  font-size: 12px;
  color: rgb(174, 174, 174);
  float: right;
}

.word-number-active {
  font-size: 12px;
  color: rgb(255, 69, 69);
  float: right;
}

/deep/.ant-modal-footer {
  border-top: none !important;
}
</style>
