<!--
 * @Author: gaojingran
 * @Date: 2021-04-13 15:00:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-21 14:23:43
 * @Description: 订单进度时间轴
-->

<style lang="less" scoped>
.step-container {
  display: flex;
  transform: translateZ(0);
  .step-item {
    &.active {
      .line-container .circle {
        border-color: @primary-color;
        background-color: @primary-color;
      }
      .date,
      .details .text {
        color: @text_color_1;
      }
    }
    &.success {
      .date,
      .details .text {
        color: @text_color_1;
      }
    }
    .date {
      text-align: center;
      margin-bottom: 8px;
      color: @text_color_2;
    }
    .line-container {
      margin-bottom: 8px;
      padding: 6px 0;
      position: relative;
      .line {
        height: 2px;
        background: #dee8f3;
      }
      .circle {
        display: block;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 1px;
        width: 12px;
        height: 12px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 100%;
        border: 2px solid @border_color_default;
      }
      .icon {
        display: block;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 1px;
        font-size: @font_size_2;
        color: @success-color;
      }
    }
    .details {
      .text {
        padding: 0 5px;
        text-align: center;
        margin: 0 0 5px;
        color: @text_color_2;
      }
    }
  }
}
.prve,
.next {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
  .icon {
    position: absolute;
    top: 27px;
    cursor: pointer;
    color: @text_color_3;
    font-size: @font_size_2;
  }
}
.prve {
  left: 0;
  padding-right: 20px;
}
.next {
  right: 0;
  padding-left: 20px;
  .icon {
    right: 0;
  }
}
</style>

<template>
  <NeoNoScrollWrap ref="scollWrap" :style="{ height: `${scrollWrapperHeight}px` }">
    <div class="step-container" ref="container" :style="{ width: `${contianerWidth}px` }">
      <div
        class="step-item"
        v-for="(item, i) in options"
        :key="i"
        :style="{ width: `${itemWidth}px` }"
        :class="{
          active: item.active,
          //success: item > 6,
        }"
      >
        <template v-if="item.type !== 'next'">
          <div class="date">{{ item.createDate }}</div>
          <div class="line-container">
            <div class="line" />
            <span class="circle"></span>
            <!-- <a-icon v-else class="icon" type="check-circle" theme="filled" /> -->
          </div>
          <div class="details">
            <p class="text">{{ item.info }}</p>
            <p class="text">{{ $t('orderInfo.operator') }}: {{ item.creatorName }}</p>
            <p class="text" v-if="item.creatorPhone">{{ $t('orderInfo.contact') }}: {{ item.creatorPhone }}</p>
          </div>
        </template>
        <template v-if="item.type === 'next'">
          <div class="date">
            <span style="opacity: 0">占个高度</span>
          </div>
          <div class="line-container">
            <div class="line" />
            <span class="circle"></span>
            <!-- <a-icon v-else class="icon" type="check-circle" theme="filled" /> -->
          </div>
          <div class="details">
            <p class="text">{{ $t('orderInfo.current') }}</p>
            <p class="text">{{ item.info }}</p>
          </div>
        </template>
      </div>
    </div>
    <template v-if="showPrevBtn" v-slot:outer>
      <div class="prve">
        <a-icon class="icon" type="double-left" @click="handlePrev" />
      </div>
      <div class="next">
        <a-icon class="icon" type="double-right" @click="handleNext" />
      </div>
    </template>
  </NeoNoScrollWrap>
</template>

<script>
import NeoNoScrollWrap from '@/components/common/NeoNoScrollWrap'

export default {
  name: 'TimeStep',
  components: {
    NeoNoScrollWrap,
  },
  props: {
    // 单滑块宽度
    itemWidth: {
      type: Number,
      default: 220,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      scrollWrapperHeight: 0,
      showPrevBtn: false,
    }
  },
  computed: {
    contianerWidth() {
      return this.options.length * this.itemWidth
    },
  },
  methods: {
    init() {
      // 设置最外层container高度
      const scrollWrap = this.$refs.scollWrap.getScrollWrapper()
      this.scrollWrapperHeight = this.$refs.container.clientHeight || 0
      // 滚动按钮
      this.showPrevBtn = this.contianerWidth > scrollWrap.clientWidth
      // 滚动到末尾
      scrollWrap.scrollTo({ left: this.contianerWidth, behavior: 'smooth' })
      setTimeout(() => {
        this.$emit('TimeStepLoaded')
      }, 200)
    },

    handlePrev() {
      if (this.$refs.scollWrap) {
        const scrollWrap = this.$refs.scollWrap.getScrollWrapper()
        scrollWrap.scrollTo({ left: scrollWrap.scrollLeft - this.itemWidth - 20, behavior: 'smooth' })
      }
    },

    handleNext() {
      if (this.$refs.scollWrap) {
        const scrollWrap = this.$refs.scollWrap.getScrollWrapper()
        scrollWrap.scrollTo({ left: scrollWrap.scrollLeft + this.itemWidth + 20, behavior: 'smooth' })
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
