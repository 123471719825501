<template>
  <a-modal
    :width="1100"
    :title="$t('orderInfo.btn_create_quote')"
    :maskClosable="false"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel"
  >
    <a-spin :spinning="loading">
      <div class="create-quote-modal">
        <template v-if="oldProcess">
          <a-steps v-model="current" size="small" style="width: 60%" class="_mb-20">
            <a-step :disabled="disabled" :title="$t('RockWell.text1')" />
            <a-step :disabled="disabled" :title="$t('RockWell.text2')" />
          </a-steps>
          <div v-if="current == 0">
            <a-form-model class="mt-4" ref="form" :colon="true" :model="form">
              <a-table
                class="head-bg mb-4 _mt-25"
                size="middle"
                rowKey="targetCode"
                v-if="tableShow"
                :data-source="form.dataSource"
                :pagination="false"
                :bordered="true"
              >
                <a-table-column key="targetCode" align="center" :width="130" :title="$t('orderInfo.table_lang')">
                  <template slot-scope="text">
                    <span>{{ $store.getters['app/getLangNameByCode'](text.targetCode) }}</span>
                  </template>
                </a-table-column>

                <a-table-column-group>
                  <span slot="title">{{ $t('orderInfo.table_matching') }}</span>
                  <a-table-column v-for="k in tableColumn" :key="k" align="center" :title="k" :width="116">
                    <template slot-scope="text, _, index">
                      <a-form-model-item
                        style="margin-bottom: 0px"
                        class="table-item"
                        :labelCol="{ span: 0 }"
                        :wrapperCol="{ span: 24 }"
                        :prop="`dataSource.` + index + `.${k}.value`"
                        :rules="[validate.required, validate.decimal_0_1]"
                      >
                        <a-input
                          v-show="text[k].edit"
                          @blur="handleBlur(text[k], $event)"
                          v-model="text[k].value"
                          size="small"
                          :ref="`dataSource.` + index + `.${k}`"
                          :id="`dataSource.` + index + `.${k}`"
                          @change="dispatchResize"
                        />
                        <div
                          style="min-width: 50px"
                          v-show="!text[k].edit"
                          @click="handleClick(text[k], `dataSource.` + index + `.${k}`)"
                        >
                          {{ text[k].value }}
                        </div>
                      </a-form-model-item>
                    </template>
                  </a-table-column>
                </a-table-column-group>
              </a-table>
            </a-form-model>

            <a-button type="primary" @click="handleNext" :loading="btnloading">{{ $t('next') }}</a-button>
          </div>

          <CreateQuoteModalSecondStep v-else :info="info" :parent="parent" @openQuoteDrawer="handleQuoteDrawer" />
        </template>
        <template v-else>
          <CreateQuoteModalSecondStep
            :info="info"
            :parent="parent"
            :oldProcess="oldProcess"
            @openQuoteDrawer="handleQuoteDrawer"
          />
        </template>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import CreateQuoteModalSecondStep from './CreateQuoteModalSecondStep.vue'
import validate from '@/configs/validate.js'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    parent: {
      type: Object,
    },
    // 订单信息
    info: {
      type: Object,
      default: () => ({}),
    },
    oldProcess: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CreateQuoteModalSecondStep,
  },
  data() {
    return {
      loading: false,
      btnloading: false,
      current: 0,
      disabled: true,
      validate: window.$g.validate,
      ratio: [], //折扣比率
      dataSource0: [],
      decimal_0_1: validate.decimal_0_1,
      tableColumn: window.$g.wordReportRangeColumn[0].range,
      form: {
        forecastWorkdays: undefined,
        dataSource: [],
      },
      tableShow: true,
    }
  },
  watch: {
    visible(visible) {
      if (!visible) {
        setTimeout(() => {
          this.current = 0
          this.tableShow = false
          setTimeout(() => {
            this.tableShow = true
          }, 200)
        }, 200)
      } else {
        this.init()
      }
    },
  },
  mounted() {
    !this.$g.isCust && this.init()
  },
  methods: {
    handleClick(item, id) {
      console.log({ item, id })
      item.edit = true
      const dom = document.getElementById(id)
      setTimeout(() => {
        dom.focus()
      })
    },
    init() {
      this.getRadio()
    },
    handleQuoteDrawer() {
      this.handleCancel()
      this.$emit('openQuoteDrawer')
    },
    async saveOrUpdateWordReportRockWell() {
      try {
        const rockWellOrderWwcRequestList = this.form.dataSource
          .map((obj) => {
            const arr = Object.entries(obj)
              .map((item) => {
                if (item[0] !== 'targetCode') {
                  return {
                    discountRange: item[0],
                    discountValue: item[1].value,
                    targetCode: obj.targetCode,
                  }
                }
              })
              .filter(Boolean)

            return arr
          })
          .flat()
        console.log('rockWellOrderWwcRequestList :>> ', rockWellOrderWwcRequestList)
        const params = {
          orderId: this.$route.query.id,
          rockWellOrderWwcRequestList,
        }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('saveOrUpdateWordReportRockWell', params)
        console.log(`data`, data)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    async handleNext() {
      const allOk = this.closeAllEdit()
      if (!allOk) {
        return
      }
      this.btnloading = true
      console.log('handleNext')
      await this.saveOrUpdateWordReportRockWell()
      this.current = 1
      this.btnloading = false
    },
    validates(value) {
      return this.decimal_0_1.pattern.test(value)
    },
    closeAllEdit() {
      let allOk = true
      this.form.dataSource.forEach((item) => {
        const radios = Object.entries(item)
        radios.forEach((_) => {
          const key = _[0]
          const obj = _[1]
          const { value } = obj
          if (key !== 'targetCode') {
            if (this.validates(value)) {
              obj.edit = false
            } else {
              console.error('不符合正则匹配')
              allOk = false
            }
          }
        })
      })
      console.log(`allOk`, allOk)
      return allOk
    },
    handleBlur(item, event) {
      console.log({ item, event })
      const v = item.value.replace(/ /g, '')
      if (this.$g.noEmpty(v)) {
        item.edit = false
      }
    },
    dispatchResize() {},
    async getRadio() {
      try {
        const data = await this.$http({
          key: 'getInitWwcRatioRockWell',
          params: { id: this.$route.query.id },
        })
        console.log(`data`, data)
        this.radio = data

        this.dataSource0 = data

        this.form.dataSource = data.map((_) => {
          const obj = {}
          _.rockWellOrderWwcDTOList.forEach((__) => {
            obj[__.discountRange] = { edit: false, value: __.discountValue }
          })

          return {
            ...obj,
            targetCode: _.targetCode,
          }
        })
        console.log('this.form.dataSource :>> ', this.form.dataSource)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    handleCancel() {
      if (this.loading) return
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep {
  .ant-table-tbody .ant-table-row-cell-break-word {
    padding: 0 !important;
  }
}
</style>
